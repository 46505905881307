/* Navbar and footer */

.Navbar .logo {
  width: 60px;
  height: 60px;
}

.nav-item {
  margin: 0 55px;
}

@media only screen and (max-width: 1200px) {
  .nav-item {
    margin: 0 40px;
  }
}

.arrow {
  color: rgba(0, 0, 0, 0.3);
  margin-left: 0.3rem;
}

.copyright {
  font-size: 0.7rem;
}

.footer-text {
  font-size: 0.8rem;
  display: inline-block;
}

.dropdown-item {
  font-size: 0.9rem;
  text-align: center;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

/* Nav stroke effect */

nav ul {
  list-style: none;
  text-align: center;
}

nav ul li {
  display: inline-block;
}

nav ul li .nav-link,
nav ul li .nav-link:after,
nav ul li .nav-link:before {
  transition: all 0.3s;
}

.nav-link {
  position: relative;
}

.nav-link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '';
  color: transparent;
  background: rgba(255, 0, 0, 0.3);
  height: 1px;
}
.nav-link-active {
  box-shadow: inset 0 -1px 0 rgba(255, 0, 0, 0.3);
}

.nav-link:hover:after {
  width: 100%;
}

.dropdown-item:focus {
  color: black;
  background-color: white;
}

/* Rainbow Line */

.navbar,
.footer {
  border-width: 1px;
  border-style: solid;
  border-left: 0;
  border-right: 0;
  border-image: linear-gradient(
      90deg,
      white,
      rgba(255, 0, 0, 1) 30%,
      rgba(255, 154, 0, 1),
      rgba(208, 222, 33, 1),
      rgba(79, 220, 74, 1),
      rgba(63, 218, 216, 1),
      rgba(47, 201, 226, 1),
      rgba(28, 127, 238, 1),
      rgba(95, 21, 242, 1),
      rgba(186, 12, 248, 1),
      rgba(251, 7, 217, 1) 70%,
      white
    )
    10% 30%;
}

.navbar {
  font-size: 0.9rem;
  text-transform: uppercase;
  border-top: 0;
}

.footer {
  border-bottom: 0;
}

/* Shrink effect */

.shrink {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 55px !important;
}

.shrink .container .navbar-brand .logo {
  width: 40px;
  height: 40px;
}

.shrink Link:not(.item-shrink, .not-resize) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.resize {
  width: 60px;
  height: 60px;
}

.toggler-shrink {
  width: 25px;
  height: 25px;
}

.resize-toggler-shrink {
  width: 30px;
  height: 30px;
}

.shrink,
.shrink .container .navbar-brand .logo,
.resize,
.toggler-shrink,
.resize-toggler-shrink {
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
}

/* Keyframes */

@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

@keyframes circle {
  0% {
    width: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 1px;
    z-index: -1;
    background: #eee;
    border-radius: 100%;
  }
  100% {
    background: #aaa;
    height: 5000%;
    width: 5000%;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 0;
  }
}
