.subtitle {
  font-size: 1.1rem;
}

hr {
  border: 0;
  height: 1px;
  background: rgba(73, 73, 73, 0.425);
  background-image: linear-gradient(to right, #ccc, rgba(73, 73, 73, 0.425), #ccc);
}

.intro-text {
  font-size: 1.04rem;
}
