@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding-top: 87px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none !important;
}

#root .btn-light {
  background-color: #e9ecef;
  border-color: #e9ecef;
  color: black;
}

#root .btn-light:focus {
  outline: none;
  box-shadow: none;
  background-color: #e9ecef;
  border-color: #e9ecef;
  color: black;
}
@media only screen and (max-width: 970px) {
  #root .btn-light:focus {
    outline: none;
    box-shadow: none;
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
    color: black !important;
  }
}

#root .btn-light:hover {
  color: white;
  background-color: #161310; /* #161310  #747677*/
}

.bg-light {
  background-color: #e9ecef !important;
}
