/* Foto lunghe */
.panoramic {
  max-height: 245px;
  width: 100%;
  object-fit: cover;
  object-position: 50% 10%;
}
/* Team */
.team-img {
  width: 200px;
  height: 200px;
}
/* Partner */
tr {
  font-size: 0.9rem;
}
/* Rilievi Idro */
.attrezzatura-scroller {
  height: 300px;
  overflow: scroll;
}
.attrezzatura-scroller::-webkit-scrollbar {
  width: 0.3em;
}
.attrezzatura-scroller::-webkit-scrollbar-thumb {
  background-color: #e9ecef;
  border-radius: 5px;
}
/* Contatti */
.fa-external-link-alt {
  font-size: 0.9rem;
}
.map-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
}
:focus {
  outline: none;
  outline-color: none;
  outline-style: none;
}
/* Esperienze */
.esperienze-img {
  background-size: contain;
  width: 55vh;
  height: 33vh;
}
.figcaption-esperienze {
  text-align: center;
  font-size: 1.1rem;
}
.fa-long-arrow-alt-right {
  font-size: 0.9rem;
}
@media only screen and (max-width: 576px) {
  .esperienze-img {
    height: 38vh;
  }
}
/* SubEspImg */
.figcaption-subcategories {
  font-size: 0.8rem;
}
.subcategories-img {
  background-size: contain;
  width: 78vh;
  height: 50vh;
}
.fa-chevron-right,
.fa-chevron-left {
  font-size: 0.8rem;
}
.pubblicazioni-img {
  object-fit: contain;
  width: 78vh;
  height: 50vh;
}
@media only screen and (max-width: 500px) {
  .subcategories-img {
    height: 38vh;
  }
}
/* Image hover effect */
div [class*=" imghvr-"] figcaption,
div [class^="imghvr-"] figcaption {
  padding: 0px;
  background-color: white;
}
div [class*=" imghvr-"],
div [class^="imghvr-"] {
  background-color: white;
  color: #fff;
}
.figure-img {
  margin-bottom: 0rem;
  line-height: 1;
}
/* Rilievi */
.rilievi-img {
  background-size: contain;
  width: 78vh;
  height: 50vh;
}
@media only screen and (max-width: 500px) {
  .rilievi-img {
    height: 38vh;
  }
}
/* La società */
.carousel-indicators li {
  width: 16px;
  height: 0.1rem;
}
.la-societa .jumbotron {
  padding: 2rem 2rem;
  margin: 2rem 0;
}
.carousel-caption {
  -webkit-text-stroke: 0.02rem black;
}
/* Formazione */
.blockquote {
  font-size: 1rem;
}
/* NotFound */
.NotFound h2 {
  font-weight: 300;
}
/* Footer */
.social {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
.social:hover {
  transform: scale(1.8);
  transition: all 0.3s ease-in-out;
}
